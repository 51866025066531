
import { computed, defineComponent } from 'vue'
import PhotoInput from '@bd/admin/components/Forms/PhotoInput.vue'
import { PhotoDto } from '@bd/api/admin-api/types'
import { logErr, useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { adminApi } from '@bd/api'

const useOfferField = useFieldOf<OfferDetailsDto>()
const EMPTY_PHOTO = { photoUrl: '', photoId: '' }

type PhotoId = PhotoDto['photoId']

const uploadPhoto = async (file: File) => {
  const formData = new FormData()
  formData.append('photo', file)
  return (await adminApi.uploadImage(formData)).data
}

export const removePhoto = (curr: PhotoDto[], id: PhotoId) => {
  const result = curr.filter((photo) => photo.photoId !== id)
  return result
}

export default defineComponent({
  name: 'GalleryPhotos',
  components: { PhotoInput },
  setup() {
    const { value } = useOfferField('galleryImages')
    const photos = computed(() => [...(value.value || []), EMPTY_PHOTO])

    const onUploadHandle = async (files: File[]) => {
      const results = await Promise.allSettled(files.map(uploadPhoto))
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          value.value = [...(value.value || []), result.value]
        } else {
          logErr(result.reason)
        }
      })
    }

    const move = (curr: PhotoDto[], id: PhotoId, direction: string) => {
      const arr = [...curr]
      const idx = arr.findIndex(({ photoId }) => photoId === id)

      if (direction === 'left' && idx > 0) {
        let el = arr[idx]
        arr[idx] = arr[idx - 1]
        arr[idx - 1] = el
        return arr
      }

      if (direction === 'right' && idx !== -1 && idx < arr.length - 1) {
        let el = arr[idx]
        arr[idx] = arr[idx + 1]
        arr[idx + 1] = el
        return arr
      }
    }

    const onMoveLeft = (id: PhotoId) => {
      value.value = move(value.value || [], id, 'left')
    }

    const onMoveRight = (id: PhotoId) => {
      value.value = move(value.value || [], id, 'right')
    }

    const onRemoveHandle = (id: PhotoId) => {
      value.value = removePhoto(value.value || [], id)
    }

    return {
      photos,
      onUploadHandle,
      onRemoveHandle,
      onMoveRight,
      onMoveLeft,
    }
  },
})
