
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { BUILDING_TYPES, NumberInput, Select, TextInput } from '@bd/components'
import { additionalInformationOfferForm } from '@bd/admin/config'
import { AdditionalInformationOffer, InputType } from '@bd/admin/types'
import { PropertyType } from '@bd/api'
import { useField } from 'vee-validate'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'

export default defineComponent({
  name: 'OfferAdditionalInformationForm',
  components: { TextInput, Select, NumberInput },
  setup() {
    const { t } = useI18n()

    const propertyType = useField<PropertyType | undefined>('propertyType')

    const buildingTypeOptions = computed(() => {
      const type = propertyType.value.value
      if (!type) return []
      const { translationKey, enumValues } = BUILDING_TYPES[type]
      return enumConvertToValue(enumValues, translationKey)
    })

    const buildingTypeLabel = computed(() => {
      if (propertyType.value.value === PropertyType.PLOT) {
        return 'offers.plotType'
      }
      return 'offers.buildingType'
    })

    const additionalInformation = computed(() => {
      return additionalInformationOfferForm.map(
        (additionalInformationOffers: AdditionalInformationOffer[]) => {
          return additionalInformationOffers.map(
            (item: AdditionalInformationOffer) => {
              if (item.name === 'buildingType') {
                return {
                  ...item,
                  options: buildingTypeOptions.value,
                  label: buildingTypeLabel.value,
                }
              }
              return item
            },
          )
        },
      )
    })

    return {
      t,
      InputType,
      additionalInformation,
    }
  },
})
