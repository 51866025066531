<template>
  <div class="offer-save-form">
    <OfferLocationForm
      :offerIsDraft="offerIsDraft"
      :isAddOfferMode="isAddOfferMode"
    />
    <Divider class="my-5" />
    <OfferBasicInformationForm />
    <Divider class="my-5" />
    <OfferDescriptionForm />
    <Divider class="my-5" />
    <OfferAdditionalInformationForm />
    <Divider class="my-5" />
    <OfferImagesForm />
    <Divider class="my-5" />
    <div class="d-flex justify-content-end">
      <template v-if="isAddOfferMode">
        <div>
          <Button
            class="p-button-sm p-button-secondary m-1"
            :label="t('offers.addAsDraft')"
            @click="$emit('save-as-draft')"
          />
          <Button
            class="p-button-sm p-button-primary m-1"
            :label="t('offers.add')"
            type="submit"
          />
        </div>
      </template>
      <template v-else-if="offerIsDraft">
        <div>
          <Button
            class="p-button-sm p-button-secondary m-1"
            :label="t('offers.removeOffer')"
            @click="$emit('remove-offer')"
          />
          <Button
            class="p-button-sm p-button-secondary m-1"
            :label="t('save')"
            @click="$emit('update-draft')"
          />
          <Button
            class="p-button-sm p-button-primary m-1"
            :label="t('offers.publish')"
            @click="$emit('update-and-publish-offer')"
          />
        </div>
      </template>
      <template v-else>
        <div>
          <Button
            class="p-button-sm p-button-secondary m-1"
            :label="t('offers.removeOffer')"
            @click="$emit('remove-offer')"
          />
          <Button
            class="p-button-sm p-button-secondary m-1"
            :label="t('offers.addStatistics')"
            @click="$emit('add-statistics')"
          />
          <Button
            class="p-button-sm p-button-secondary m-1"
            :label="t('offers.unpublish')"
            @click="$emit('unpublish-offer')"
          />
          <Button
            class="p-button-sm p-button-secondary m-1"
            :label="t('offers.exportToAsari')"
            @click="$emit('export-to-asari')"
          />
          <Button
            class="p-button-sm p-button-primary m-1"
            :label="t('save')"
            @click="$emit('update-and-publish-offer')"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OfferLocationForm from './OfferLocationForm.vue'
import Divider from 'primevue/divider'
import { useI18n } from 'vue-i18n'
import OfferBasicInformationForm from './OfferBasicInformationForm.vue'
import OfferDescriptionForm from './OfferDescriptionForm.vue'
import OfferAdditionalInformationForm from './OfferAdditionalInformationForm.vue'
import OfferImagesForm from './OfferImagesForm.vue'

export default defineComponent({
  name: 'OfferSaveForm',
  props: {
    offerIsDraft: {
      type: Boolean,
      default: false,
    },
    isAddOfferMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'save-as-draft',
    'update-and-publish-offer',
    'remove-offer',
    'unpublish-offer',
    'export-to-asari',
    'add-statistics',
    'update-draft',
  ],
  components: {
    Divider,
    OfferLocationForm,
    OfferBasicInformationForm,
    OfferDescriptionForm,
    OfferAdditionalInformationForm,
    OfferImagesForm,
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped>
.p-divider::before {
  border: $divider-border;
}
</style>
