
import { defineComponent, computed, ref } from 'vue'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import { useToast } from 'primevue/usetoast'
import { useBreakpoints, TextInput } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { adminApi, commonApi, ProvinceDto } from '@bd/api'
import { CityAddDtoWithProvince } from '@bd/api/admin-api/types'
import { OffersEventMessages } from '@bd/admin/config/toast'

export default defineComponent({
  name: 'OfferAddCityModal',
  components: {
    Dialog,
    TextInput,
    Dropdown,
  },
  emits: ['set-city'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const { width, sizes } = useBreakpoints()
    const mobileSize = computed(() => width.value <= sizes.md)
    const provinces = ref<ProvinceDto[]>([])
    const toast = useToast()

    const form = ref<CityAddDtoWithProvince>({
      cityName: null,
      districtNames: [],
      provinceId: null,
    })

    const getProvinces = async () => {
      const { data } = await commonApi.provinces()
      provinces.value = data
    }

    const createCity = async () => {
      try {
        const { data } = await adminApi.addCity(form.value)
        emit('set-city', data)
        toast.add(OffersEventMessages.addCitySuccess)
      } catch (error) {
        toast.add(OffersEventMessages.addCityError)
      }
    }

    getProvinces()

    return {
      t,
      mobileSize,
      createCity,
      form,
      provinces,
    }
  },
})
