<template>
  <div class="image-input d-flex justify-content-center align-items-center">
    <template v-if="photo.photoId.length">
      <div class="position-buttons">
        <Button
          @click="$emit('move-left', photo.photoId)"
          icon="pi pi-chevron-left"
          :disabled="positions.isFirst"
          style="opacity: 0.6"
          class="p-button-rounded p-button-primary p-button-sm mx-1"
        />

        <Button
          @click="$emit('move-right', photo.photoId)"
          icon="pi pi-chevron-right"
          :disabled="positions.isLast"
          style="opacity: 0.6"
          class="p-button-rounded p-button-primary p-button-sm mx-1"
        />
      </div>
      <div class="image-input-preview w-100 h-100">
        <img :src="photo.photoUrl" class="image-input-preview-src w-100" />
      </div>
      <img
        class="image-input-remove-icon"
        :src="require('@bd/admin/assets/icons/delete.svg')"
        @click="$emit('remove', photo.photoId)"
      />
    </template>
    <Button
      v-else
      class="p-button-icon-only p-button-circle p-button-primary"
      icon="pi pi-plus"
      @click="onSelectImage"
    />
    <input
      ref="image"
      class="d-none"
      type="file"
      multiple
      accept="image/*"
      @change="onFileChanged"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { CustomInputEvent } from '@bd/admin/types'
import { PhotoDto } from '@bd/api/admin-api/types'

interface Positions {
  isFirst: boolean
  isLast: boolean
}

export default defineComponent({
  name: 'ImageInput',
  emits: ['upload', 'remove', 'move-left', 'move-right'],
  props: {
    photo: {
      type: Object as PropType<PhotoDto>,
      default: () => ({ photoUrl: '', photoId: '' }),
    },
    positions: {
      type: Object as PropType<Positions>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const image = ref<HTMLInputElement | null>(null)

    const onSelectImage = () => {
      image.value?.click()
    }

    const onFileChanged = (event: CustomInputEvent) => {
      const files = Array.from(event.target.files)
      emit('upload', files)
    }

    return {
      onSelectImage,
      image,
      onFileChanged,
    }
  },
})
</script>

<style lang="scss" scoped>
.image-input {
  position: relative;
  border-radius: 6px;
  background-color: $white;
  width: 125px;
  height: 125px;
}

.move-to-beginning {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5px;
  top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  border-radius: 50%;
  border: solid 1px #e8e9eb;

  &:hover {
    cursor: pointer;
  }
}

.image-input-remove-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5px;
  bottom: -10px;

  &:hover {
    cursor: pointer;
  }
}

.image-input-preview {
  overflow: hidden;
  @include flex;
  border-radius: 6px;
}

.position-buttons {
  position: absolute;
  top: 0;
}
</style>
