
import { defineComponent } from 'vue'
import PropertyTypeSelectItem from './PropertyTypeSelectItem.vue'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import { PropertyType } from '@bd/api'
import { useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'

const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'PropertyTypeSelect',
  components: { PropertyTypeSelectItem },
  setup() {
    const { value, errorMessage } = useOfferField('propertyType')
    const propertyTypes = enumConvertToValue(PropertyType, 'propertyType')

    return {
      propertyTypes,
      value,
      errorMessage,
    }
  },
})
