import { EditOfferDto } from '@bd/api/admin-api/types'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { ErrorResponse } from '@bd/api'
import { adminValidationService, TOAST_CONFIG } from '@bd/components'
import { SubmissionContext } from 'vee-validate'

export const getEditOfferDto = (details: OfferDetailsDto) => {
  const {
    cityDto,
    districtDto,
    offerSellerDto,
    draft,
    galleryImages,
    galleryImagesUrls,
    ...rest
  } = details

  const offerToUpdate: EditOfferDto = {
    ...rest,
    cityId: cityDto?.cityId ?? null,
    districtId: districtDto?.districtId,
    sellerId: offerSellerDto?.id ?? null,
    publish: !draft,
    propertyType: details.propertyType ?? null,
    photoIds: galleryImages?.map((photo) => photo.photoId) || [],
  }
  return offerToUpdate
}

export const handleOfferError = (
  error: ErrorResponse | undefined,
  context: SubmissionContext<Partial<OfferDetailsDto>>,
) => {
  const fieldErrors = adminValidationService.handleValidation(error)
  if (fieldErrors) {
    context.setErrors(fieldErrors)
  }

  const errorMessage = adminValidationService.handleMessage(error)
  if (errorMessage) {
    return {
      severity: 'error',
      summary: errorMessage,
      life: TOAST_CONFIG.messageDuration.error,
    }
  }
}
