<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row mb-4">
        <div class="col-md-12">
          <span class="h-4 mb-3">{{ t('offers.basicInformation') }}</span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <NumberInput
                name="price"
                :label="t('offers.price')"
                :placeholder="t('offers.pricePlaceholder')"
                :floating="false"
                unit="currency"
                whiteBackground
              />
            </div>
            <div class="col-md-6">
              <NumberInput
                name="houseArea"
                :label="t('offers.houseArea')"
                :placeholder="t('offers.houseAreaPlaceholder')"
                :floating="false"
                whiteBackground
                unit="area"
                :minFractionDigits="2"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 white-bg d-flex flex-column field-spacing">
              <label class="mb-2 body-12 text-500">
                {{ t('offers.pricePerMeter') }}
              </label>
              <InputNumber
                :modelValue="costPerMeter"
                :minFractionDigits="0"
                mode="currency"
                :currency="currency"
                :locale="locale"
                disabled
                :label="t('offers.pricePerMeter')"
              />
              <small class="body-12 mt-2">{{
                t('offers.pricePerMeterMessage')
              }}</small>
            </div>
            <div class="col-md-6">
              <NumberInput
                name="rooms"
                :label="t('offers.rooms')"
                :placeholder="t('offers.floorPlaceholder')"
                :floating="false"
                whiteBackground
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <TextInput
                name="landAndMortgageRegisterNumber"
                type="text"
                :label="t('offers.landAndMortgageRegisterNumber')"
                :placeholder="
                  t('offers.landAndMortgageRegisterNumberPlaceholder')
                "
                :floating="false"
                whiteBackground
              />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <NumberInput
                name="floor"
                :label="t('offers.floor')"
                :placeholder="t('offers.floorPlaceholder')"
                :floating="false"
                whiteBackground
              />
            </div>
            <div class="col-md-6">
              <NumberInput
                name="levels"
                :label="t('offers.levels')"
                :placeholder="t('offers.floorPlaceholder')"
                :floating="false"
                whiteBackground
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <NumberInput
                name="constructionYear"
                :label="t('offers.constructionYear')"
                :placeholder="t('offers.constructionYearPlaceholder')"
                :floating="false"
                whiteBackground
              />
            </div>
            <div class="col-md-6">
              <Select
                name="propertyMarket"
                :options="propertyMarketTypes"
                :label="t('offers.market')"
                :placeholder="t('select')"
                whiteBackground
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <NumberInput
                name="landArea"
                :label="t('offers.landArea')"
                :placeholder="t('offers.landAreaPlaceholder')"
                :floating="false"
                whiteBackground
                unit="area"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { NumberInput, Select, TextInput } from '@bd/components'
import { LOCALISATION_SETTINGS } from '@bd/admin/config'
import { PropertyMarket } from '@bd/api'
import { PropertyMarketTypeData } from '@bd/admin/types'
import { useField } from 'vee-validate'
import InputNumber from 'primevue/inputnumber'

export default defineComponent({
  name: 'OfferBasicInformationForm',
  components: { Select, NumberInput, TextInput, InputNumber },
  setup() {
    const { t } = useI18n()

    const { currency, locale } = LOCALISATION_SETTINGS

    const price = useField<number>('price', (inputValue) => !!inputValue)
    const houseArea = useField<number>(
      'houseArea',
      (inputValue) => !!inputValue,
    )

    const costPerMeter = computed(() => {
      if (!price.value.value || !houseArea.value.value) {
        return null
      }
      return Math.floor(price.value.value / houseArea.value.value)
    })

    const propertyMarketTypes: PropertyMarketTypeData[] = Object.keys(
      PropertyMarket,
    ).map((key) => ({
      name: t(`enums.propertyMarket.${key}`),
      value: PropertyMarket[key as keyof typeof PropertyMarket],
    }))

    return {
      t,
      currency,
      locale,
      costPerMeter,
      propertyMarketTypes,
    }
  },
})
</script>

<style lang="scss" scoped></style>
