<template>
  <div>
    <div class="d-flex mb-1">
      <PropertyTypeSelectItem
        v-model="value"
        v-for="item of propertyTypes"
        :key="item.name"
        :item="item"
        :class="{ 'error-border': errorMessage }"
      />
    </div>
    <small v-show="errorMessage" class="p-invalid">{{ errorMessage }}</small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import PropertyTypeSelectItem from './PropertyTypeSelectItem.vue'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'
import { PropertyType } from '@bd/api'
import { useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'

const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'PropertyTypeSelect',
  components: { PropertyTypeSelectItem },
  setup() {
    const { value, errorMessage } = useOfferField('propertyType')
    const propertyTypes = enumConvertToValue(PropertyType, 'propertyType')

    return {
      propertyTypes,
      value,
      errorMessage,
    }
  },
})
</script>

<style lang="scss" scoped>
.error-border {
  border: $error-border;
}
</style>
