<template>
  <div class="row mb-4">
    <div class="col-md-12">
      <div class="row mb-4">
        <div class="col-md-12">
          <span class="h-4 mb-3">{{ t('offers.statistics') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 offer-statistics-form">
          <div class="row">
            <div class="col-md-6">
              <DatePicker
                v-model="form.values.startDate"
                name="startDate"
                :label="t('offers.fromData')"
                :placeholder="t('offers.fromDataPlaceholder')"
                :floating="false"
                :disabledDays="disabledDays"
                whiteBackground
                @update:modelValue="onDateFromChanged"
              />
            </div>
            <div class="col-md-6">
              <DatePicker
                v-model="form.values.endDate"
                name="endDate"
                :label="t('offers.toData')"
                :placeholder="t('offers.toDataPlaceholder')"
                :floating="false"
                :disabled="true"
                whiteBackground
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <NumberInput
                name="numberOfViews"
                :label="t('offers.visits')"
                :placeholder="t('offers.visitsPlaceholder')"
                :floating="false"
                whiteBackground
              />
            </div>
            <div class="col-md-6">
              <NumberInput
                name="numberOfInquiries"
                type="text"
                :label="t('offers.requests')"
                :placeholder="t('offers.requestsPlaceholder')"
                :floating="false"
                whiteBackground
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { NumberInput, DatePicker } from '@bd/components'
import { AddOfferStatisticsForm } from '@bd/api/admin-api/types'
import { useForm } from 'vee-validate'
import { useStatisticsChild } from '@bd/admin/services/statistics.service'
import { statisticsSchema } from '@bd/components/yup'
import { addDays } from 'date-fns'

export default defineComponent({
  name: 'OfferStatisticsForm',
  components: {
    NumberInput,
    DatePicker,
  },
  setup() {
    const { t } = useI18n()
    const stats = useStatisticsChild()

    const form = useForm<Partial<AddOfferStatisticsForm>>({
      initialValues: stats.getInitValues(),
      validationSchema: statisticsSchema,
    })

    const firstDayOfWeek = +t('date_time.firstDayOfWeek')

    /* Disable all week days apart from the first day of the week */
    const disabledDays = computed(() => {
      return Array.from({ length: 7 }, (_, index) => index).filter(
        (val) => val !== firstDayOfWeek,
      )
    })

    const onDateFromChanged = (date: Date) => {
      form.setFieldValue('endDate', addDays(date, 6))
    }

    stats.setForm(form)

    return {
      t,
      form,
      disabledDays,
      onDateFromChanged,
    }
  },
})
</script>

<style lang="scss" scoped>
.offer-statistics-form {
  background-color: $port-gore;
  padding: 40px 30px;
  border-radius: 8px;
}
</style>
