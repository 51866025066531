<template>
  <div class="gallery-photos">
    <transition-group name="flip-list" mode="out-in">
      <PhotoInput
        v-for="(photo, index) of photos"
        :key="photo.photoId"
        :photo="photo"
        :positions="{
          isFirst: index === 0,
          isLast: index === photos.length - 2,
        }"
        @upload="onUploadHandle"
        @remove="onRemoveHandle"
        @move-left="onMoveLeft"
        @move-right="onMoveRight"
      />
    </transition-group>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import PhotoInput from '@bd/admin/components/Forms/PhotoInput.vue'
import { PhotoDto } from '@bd/api/admin-api/types'
import { logErr, useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { adminApi } from '@bd/api'

const useOfferField = useFieldOf<OfferDetailsDto>()
const EMPTY_PHOTO = { photoUrl: '', photoId: '' }

type PhotoId = PhotoDto['photoId']

const uploadPhoto = async (file: File) => {
  const formData = new FormData()
  formData.append('photo', file)
  return (await adminApi.uploadImage(formData)).data
}

export const removePhoto = (curr: PhotoDto[], id: PhotoId) => {
  const result = curr.filter((photo) => photo.photoId !== id)
  return result
}

export default defineComponent({
  name: 'GalleryPhotos',
  components: { PhotoInput },
  setup() {
    const { value } = useOfferField('galleryImages')
    const photos = computed(() => [...(value.value || []), EMPTY_PHOTO])

    const onUploadHandle = async (files: File[]) => {
      const results = await Promise.allSettled(files.map(uploadPhoto))
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          value.value = [...(value.value || []), result.value]
        } else {
          logErr(result.reason)
        }
      })
    }

    const move = (curr: PhotoDto[], id: PhotoId, direction: string) => {
      const arr = [...curr]
      const idx = arr.findIndex(({ photoId }) => photoId === id)

      if (direction === 'left' && idx > 0) {
        let el = arr[idx]
        arr[idx] = arr[idx - 1]
        arr[idx - 1] = el
        return arr
      }

      if (direction === 'right' && idx !== -1 && idx < arr.length - 1) {
        let el = arr[idx]
        arr[idx] = arr[idx + 1]
        arr[idx + 1] = el
        return arr
      }
    }

    const onMoveLeft = (id: PhotoId) => {
      value.value = move(value.value || [], id, 'left')
    }

    const onMoveRight = (id: PhotoId) => {
      value.value = move(value.value || [], id, 'right')
    }

    const onRemoveHandle = (id: PhotoId) => {
      value.value = removePhoto(value.value || [], id)
    }

    return {
      photos,
      onUploadHandle,
      onRemoveHandle,
      onMoveRight,
      onMoveLeft,
    }
  },
})
</script>

<style lang="scss" scoped>
.gallery-photos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  column-gap: 10px;
  row-gap: 20px;
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
