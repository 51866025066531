<template>
  <Dialog
    maximizable
    class="add-city-dialog"
    :class="{ 'p-dialog-maximized': mobileSize }"
    header="&nbsp;"
    modal
    :showHeader="true"
  >
    <h1 class="header h-1">{{ t('offers.createCity') }}</h1>
    <div class="field-spacing">
      <label class="mb-2 body-12 text-500">
        {{ t('offers.selectProvince') }}
      </label>
      <Dropdown
        class="mt-2"
        v-model="form.provinceId"
        :options="provinces"
        optionLabel="provinceName"
        optionValue="provinceId"
        :placeholder="t('select')"
      />
    </div>
    <TextInput
      name="cityName"
      type="text"
      :label="t('city')"
      @change="form.cityName = $event"
      :placeholder="t('offers.createCityPlaceholder')"
      :floating="false"
    />
    <div class="submit-button-container flex-centered">
      <Button
        :disabled="!form.provinceId || !form.cityName"
        class="submit-button p-button-primary p-button-rounded"
        @click="createCity"
      >
        {{ t('submit') }}
      </Button>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import { useToast } from 'primevue/usetoast'
import { useBreakpoints, TextInput } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { adminApi, commonApi, ProvinceDto } from '@bd/api'
import { CityAddDtoWithProvince } from '@bd/api/admin-api/types'
import { OffersEventMessages } from '@bd/admin/config/toast'

export default defineComponent({
  name: 'OfferAddCityModal',
  components: {
    Dialog,
    TextInput,
    Dropdown,
  },
  emits: ['set-city'],
  setup(_, { emit }) {
    const { t } = useI18n()
    const { width, sizes } = useBreakpoints()
    const mobileSize = computed(() => width.value <= sizes.md)
    const provinces = ref<ProvinceDto[]>([])
    const toast = useToast()

    const form = ref<CityAddDtoWithProvince>({
      cityName: null,
      districtNames: [],
      provinceId: null,
    })

    const getProvinces = async () => {
      const { data } = await commonApi.provinces()
      provinces.value = data
    }

    const createCity = async () => {
      try {
        const { data } = await adminApi.addCity(form.value)
        emit('set-city', data)
        toast.add(OffersEventMessages.addCitySuccess)
      } catch (error) {
        toast.add(OffersEventMessages.addCityError)
      }
    }

    getProvinces()

    return {
      t,
      mobileSize,
      createCity,
      form,
      provinces,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;
  margin-bottom: 30px;
}
.dialog-content {
  margin: 1rem;
  margin-bottom: 2rem;
}

:deep(.add-city-dialog) {
  width: min(100vw, 488px);
  .p-dialog-header-maximize {
    display: none;
  }
  .p-dialog-content {
    padding: 0 77px 60px;
    @include breakpoint-down(xs) {
      padding: 0 25px 20px;
    }
  }
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-height: 50px;
}
.submit-button-container {
  margin-top: 3rem;
  padding: 0;
  @include breakpoint-down(md) {
    margin-top: auto;
    padding: 1rem;
  }

  .submit-button {
    height: 50px;
    justify-content: center;
    width: 100%;
  }
}

:deep(.p-dropdown) {
  width: 100%;
}
</style>
