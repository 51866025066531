<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row mb-4">
        <div class="col-md-12">
          <span class="h-4 mb-3">{{ t('offers.images') }}</span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <GalleryPhotos name="photoIds" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import GalleryPhotos from '@bd/admin/components/Forms/GalleryPhotos.vue'

export default defineComponent({
  name: 'OfferImagesForm',
  components: { GalleryPhotos },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped></style>
