
import { defineComponent } from 'vue'
import OfferLocationForm from './OfferLocationForm.vue'
import Divider from 'primevue/divider'
import { useI18n } from 'vue-i18n'
import OfferBasicInformationForm from './OfferBasicInformationForm.vue'
import OfferDescriptionForm from './OfferDescriptionForm.vue'
import OfferAdditionalInformationForm from './OfferAdditionalInformationForm.vue'
import OfferImagesForm from './OfferImagesForm.vue'

export default defineComponent({
  name: 'OfferSaveForm',
  props: {
    offerIsDraft: {
      type: Boolean,
      default: false,
    },
    isAddOfferMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'save-as-draft',
    'update-and-publish-offer',
    'remove-offer',
    'unpublish-offer',
    'export-to-asari',
    'add-statistics',
    'update-draft',
  ],
  components: {
    Divider,
    OfferLocationForm,
    OfferBasicInformationForm,
    OfferDescriptionForm,
    OfferAdditionalInformationForm,
    OfferImagesForm,
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
