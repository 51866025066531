
import { computed, defineComponent, PropType } from 'vue'
import { Svg } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { PropertyTypeData } from '@bd/admin/types'

export default defineComponent({
  name: 'PropertyTypeSelectItem',
  components: { Svg },
  emits: ['update:modelValue'],
  props: {
    item: {
      type: Object as PropType<PropertyTypeData>,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n()

    const isActive = computed(() => {
      return props.item.value === props.modelValue
    })

    return {
      t,
      isActive,
    }
  },
})
