<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row mb-4">
        <div class="col-md-12">
          <span class="h-4 mb-3">{{ t('offers.additionalInformation') }}</span>
        </div>
      </div>
      <div class="row mb-4">
        <div
          class="col-md-3"
          v-for="(column, index) of additionalInformation"
          :key="index"
        >
          <template v-for="input of column">
            <TextInput
              :key="input.name"
              v-if="input.type === InputType.TEXT_INPUT"
              :name="input.name"
              type="text"
              :label="t(input.label)"
              :placeholder="t(input.placeholder)"
              :floating="false"
              whiteBackground
            />
            <Select
              :key="input.name"
              v-if="input.type === InputType.SELECT"
              :name="input.name"
              :options="input.options"
              :label="t(input.label)"
              :placeholder="t('select')"
              whiteBackground
            />
            <NumberInput
              :key="input.name"
              v-if="input.type === InputType.NUMBER_INPUT"
              :name="input.name"
              :label="t(input.label)"
              :placeholder="t(input.placeholder)"
              :floating="false"
              :unit="input.unit"
              whiteBackground
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { BUILDING_TYPES, NumberInput, Select, TextInput } from '@bd/components'
import { additionalInformationOfferForm } from '@bd/admin/config'
import { AdditionalInformationOffer, InputType } from '@bd/admin/types'
import { PropertyType } from '@bd/api'
import { useField } from 'vee-validate'
import { enumConvertToValue } from '@bd/admin/use/EnumsConvert'

export default defineComponent({
  name: 'OfferAdditionalInformationForm',
  components: { TextInput, Select, NumberInput },
  setup() {
    const { t } = useI18n()

    const propertyType = useField<PropertyType | undefined>('propertyType')

    const buildingTypeOptions = computed(() => {
      const type = propertyType.value.value
      if (!type) return []
      const { translationKey, enumValues } = BUILDING_TYPES[type]
      return enumConvertToValue(enumValues, translationKey)
    })

    const buildingTypeLabel = computed(() => {
      if (propertyType.value.value === PropertyType.PLOT) {
        return 'offers.plotType'
      }
      return 'offers.buildingType'
    })

    const additionalInformation = computed(() => {
      return additionalInformationOfferForm.map(
        (additionalInformationOffers: AdditionalInformationOffer[]) => {
          return additionalInformationOffers.map(
            (item: AdditionalInformationOffer) => {
              if (item.name === 'buildingType') {
                return {
                  ...item,
                  options: buildingTypeOptions.value,
                  label: buildingTypeLabel.value,
                }
              }
              return item
            },
          )
        },
      )
    })

    return {
      t,
      InputType,
      additionalInformation,
    }
  },
})
</script>

<style lang="scss" scoped></style>
