
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import GalleryPhotos from '@bd/admin/components/Forms/GalleryPhotos.vue'

export default defineComponent({
  name: 'OfferImagesForm',
  components: { GalleryPhotos },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
