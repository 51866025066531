
/* global google */

import { defineComponent, PropType, shallowRef, watch } from 'vue'
import { useBreakpoints } from '@bd/components'
import { useLatest } from '@bd/helpers'
import { Loader } from '@googlemaps/js-api-loader'
import { debouncedWatch } from '@vueuse/shared'

type GeocoderResult = google.maps.GeocoderResult
const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
})

export default defineComponent({
  name: 'MapEmbed',
  props: {
    place: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object as PropType<google.maps.LatLngLiteral>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const { desktopSize } = useBreakpoints()
    const mapApiLoaded = shallowRef(false)
    const mapRoot = shallowRef<HTMLDivElement>()

    const map = shallowRef<google.maps.Map>()
    const marker = shallowRef<google.maps.Marker>()
    const geocoder = shallowRef<google.maps.Geocoder>()

    loader.load().then(() => (mapApiLoaded.value = true))

    const onPlaceChange = useLatest(queryPosition, updatePosition)
    watch([mapRoot, mapApiLoaded], initMap)
    debouncedWatch(() => props.place, onPlaceChange, {
      debounce: 1000,
    })

    return { desktopSize, mapRoot }

    function initMap() {
      if (mapRoot.value && mapApiLoaded.value) {
        geocoder.value = new google.maps.Geocoder()

        map.value = new google.maps.Map(mapRoot.value!, {
          center: props.modelValue,
          zoom: 11,
        })

        marker.value = new google.maps.Marker({
          map: map.value,
          position: props.modelValue,
          draggable: true,
        })

        marker.value.addListener('dragend', onMarkerMoved)
      }
    }

    async function queryPosition() {
      return new Promise<GeocoderResult | null>((resolve, reject) => {
        const request: google.maps.GeocoderRequest = {
          address: props.place,
          componentRestrictions: {
            country: 'PL',
          },
        }

        geocoder.value!.geocode(request, (results, status) => {
          if (status === 'OK' || status === 'ZERO_RESULTS') {
            resolve(results && results[0])
          } else {
            reject(new Error(status))
          }
        })
      })
    }

    function updatePosition(result: GeocoderResult | null) {
      const pos = result?.geometry.location || props.modelValue
      const bounds = result?.geometry.bounds
      marker.value?.setPosition(pos)
      map.value?.setCenter(pos)
      if (bounds) {
        map.value?.panToBounds(bounds)
      }

      onMarkerMoved()
    }

    function onMarkerMoved() {
      const newPos = marker.value!.getPosition()?.toJSON()
      emit('update:modelValue', newPos)
    }
  },
})
