<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row mb-4">
        <div class="col-md-12">
          <span class="h-4 mb-3">{{
            t('offers.descriptionTitleSection')
          }}</span>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <TextInput
            name="title"
            type="text"
            :label="t('offers.title')"
            :placeholder="t('offers.titlePlaceholder')"
            :floating="false"
            whiteBackground
          />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <TextareaInput
            name="description"
            :label="t('offers.descriptionInput')"
            :placeholder="t('offers.descriptionInputPlaceholder')"
            rows="15"
            whiteBackground
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { TextInput, TextareaInput } from '@bd/components'

export default defineComponent({
  name: 'OfferDescriptionForm',
  components: { TextInput, TextareaInput },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },
})
</script>

<style lang="scss" scoped></style>
