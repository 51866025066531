<template>
  <div class="app-content-layout">
    <div class="row d-none d-md-flex mb-4" v-if="backAction">
      <div class="col-12">
        <div class="d-none d-md-flex align-self-start">
          <slot name="back-action" />
        </div>
      </div>
    </div>
    <div class="row d-none d-md-flex mb-3" v-if="subtitle">
      <div class="col-12">
        <div class="d-none d-md-flex align-self-start">
          <span class="text-1">
            <slot name="subtitle" />
          </span>
        </div>
      </div>
    </div>
    <div
      class="row d-none d-md-flex mb-5"
      v-if="$slots.title || $slots.actions"
    >
      <div class="col-6 app-content-layout-title d-flex align-items-center">
        <slot name="title" />
      </div>
      <div class="col-6 d-flex justify-content-end align-items-center">
        <slot name="actions" />
      </div>
    </div>
    <div class="d-none d-md-flex flex-wrap mb-5" v-if="tools">
      <slot name="tools-filters" />
      <div class="tools-actions">
        <slot name="tools-actions" />
      </div>
    </div>
    <div class="row d-none d-md-flex mb-5" v-if="divider">
      <div class="col-12">
        <Divider />
      </div>
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Divider from 'primevue/divider'

export default defineComponent({
  name: 'AppContentLayout',
  components: { Divider },
  props: {
    backAction: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: Boolean,
      default: false,
    },
    tools: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.app-content-layout {
  margin: 1rem;
  height: 100%;
}

.app-content-layout-title {
  font-size: 40px;
  font-weight: 600;
}

.p-divider::before {
  border: solid 1px rgba($athens-gray, 0.5);
}

@include breakpoint-up(md) {
  .app-content-layout {
    margin: 60px 70px;
  }
}
.tools-actions {
  display: flex;
  justify-content: flex-end;
  flex: 1 0 auto;
}
</style>
