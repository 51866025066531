
import { defineComponent } from 'vue'
import Divider from 'primevue/divider'

export default defineComponent({
  name: 'AppContentLayout',
  components: { Divider },
  props: {
    backAction: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: Boolean,
      default: false,
    },
    tools: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: false,
    },
  },
})
