
import { defineComponent, PropType, ref } from 'vue'
import { CustomInputEvent } from '@bd/admin/types'
import { PhotoDto } from '@bd/api/admin-api/types'

interface Positions {
  isFirst: boolean
  isLast: boolean
}

export default defineComponent({
  name: 'ImageInput',
  emits: ['upload', 'remove', 'move-left', 'move-right'],
  props: {
    photo: {
      type: Object as PropType<PhotoDto>,
      default: () => ({ photoUrl: '', photoId: '' }),
    },
    positions: {
      type: Object as PropType<Positions>,
      required: true,
    },
  },
  setup(_, { emit }) {
    const image = ref<HTMLInputElement | null>(null)

    const onSelectImage = () => {
      image.value?.click()
    }

    const onFileChanged = (event: CustomInputEvent) => {
      const files = Array.from(event.target.files)
      emit('upload', files)
    }

    return {
      onSelectImage,
      image,
      onFileChanged,
    }
  },
})
