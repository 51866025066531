<template>
  <div
    class="property-type-select-item d-flex flex-column justify-content-between mx-1"
    :class="{ active: isActive }"
    @click="$emit('update:modelValue', item.value)"
  >
    <span class="body-sm-medium">{{ item.name }}</span>
    <Svg
      :src="
        isActive
          ? require('@bd/admin/assets/icons/check.svg')
          : require('@bd/admin/assets/icons/no-check.svg')
      "
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { Svg } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { PropertyTypeData } from '@bd/admin/types'

export default defineComponent({
  name: 'PropertyTypeSelectItem',
  components: { Svg },
  emits: ['update:modelValue'],
  props: {
    item: {
      type: Object as PropType<PropertyTypeData>,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { t } = useI18n()

    const isActive = computed(() => {
      return props.item.value === props.modelValue
    })

    return {
      t,
      isActive,
    }
  },
})
</script>

<style lang="scss" scoped>
.property-type-select-item {
  height: 120px;
  width: 120px;
  border-radius: 6px;
  background-color: $white;
  padding: 15px;
  cursor: pointer;
}

.active {
  background-color: $primary-color;

  :deep(.body-sm-medium) {
    color: $white;
  }
}
</style>
