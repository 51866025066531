
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { NumberInput, Select, TextInput } from '@bd/components'
import { LOCALISATION_SETTINGS } from '@bd/admin/config'
import { PropertyMarket } from '@bd/api'
import { PropertyMarketTypeData } from '@bd/admin/types'
import { useField } from 'vee-validate'
import InputNumber from 'primevue/inputnumber'

export default defineComponent({
  name: 'OfferBasicInformationForm',
  components: { Select, NumberInput, TextInput, InputNumber },
  setup() {
    const { t } = useI18n()

    const { currency, locale } = LOCALISATION_SETTINGS

    const price = useField<number>('price', (inputValue) => !!inputValue)
    const houseArea = useField<number>(
      'houseArea',
      (inputValue) => !!inputValue,
    )

    const costPerMeter = computed(() => {
      if (!price.value.value || !houseArea.value.value) {
        return null
      }
      return Math.floor(price.value.value / houseArea.value.value)
    })

    const propertyMarketTypes: PropertyMarketTypeData[] = Object.keys(
      PropertyMarket,
    ).map((key) => ({
      name: t(`enums.propertyMarket.${key}`),
      value: PropertyMarket[key as keyof typeof PropertyMarket],
    }))

    return {
      t,
      currency,
      locale,
      costPerMeter,
      propertyMarketTypes,
    }
  },
})
