<template>
  <div class="row">
    <OfferAddCityModal
      @set-city="setCityValue"
      v-if="showCityModal"
      v-model:visible="showCityModal"
    />
    <ConfirmDialog
      v-model:visible="showSellerModal"
      :title="t('confirmDialog.offers.sellerWarning')"
      @confirm="onSellerConfirm"
      @reject="onSellerReject"
    />
    <div class="col-md-6">
      <OfferStatisticsForm v-if="!offerIsDraft && !isAddOfferMode" />
      <div class="row mb-4">
        <div class="col-md-12">
          <span class="h-4 mb-3">{{ t('offers.propertyType') }}</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <PropertyTypeSelect class="mb-3" />
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-12">
          <span class="h-4 mb-3">{{ t('offers.location') }}</span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <CityAutocomplete
            :items="cities"
            :label="t('offers.city')"
            :placeholder="t('offers.cityPlaceholder')"
            @search="searchCity"
            whiteBackground
          />
        </div>
        <div class="col-md-6 add-btn d-flex align-items-center">
          <Button class="p-button-text" @click="showCityModal = true">
            <Svg
              :src="require('@bd/assets/icons/plus.svg')"
              class="mr-2 btn-icon c-primary"
            />
            <span>{{ t('offers.createCity') }}</span>
          </Button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <DistrictAutocomplete
            :items="districts"
            :label="t('offers.district')"
            :placeholder="t('offers.districtPlaceholder')"
            whiteBackground
          />
        </div>
        <div class="col-md-6">
          <TextInput
            name="street"
            type="text"
            :label="t('offers.street')"
            :placeholder="t('offers.streetPlaceholder')"
            :floating="false"
            whiteBackground
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <TextInput
            name="buildingNumber"
            type="text"
            :label="t('offers.buildingNumber')"
            :placeholder="t('offers.buildingNumberPlaceholder')"
            :floating="false"
            whiteBackground
          />
        </div>
        <div class="col-md-6">
          <TextInput
            name="houseNumber"
            type="text"
            :label="t('offers.houseNumber')"
            :placeholder="t('offers.houseNumberPlaceholder')"
            :floating="false"
            whiteBackground
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <SellerAutocomplete
            :items="searchSellerList"
            :label="t('offers.seller')"
            :placeholder="t('offers.sellerPlaceholder')"
            whiteBackground
            @search="searchSellers"
            @show-warning-modal="onWarningModal"
          />
        </div>
        <div class="col-md-6 add-btn d-flex align-items-center">
          <Button
            class="p-button-text"
            @click="goToSellerDetails"
            v-if="showSellerDetailsNavigation"
          >
            <span>{{ t('offers.sellerDetails') }}</span>
            <Svg
              :src="require('@bd/assets/icons/chevron-right.svg')"
              class="ml-2 btn-icon c-primary"
            />
          </Button>
          <Button
            v-else-if="canAddSeller"
            class="p-button-text"
            @click="goToAddSellerClick"
          >
            <Svg
              :src="require('@bd/assets/icons/plus.svg')"
              class="mr-2 btn-icon c-primary"
            />
            <span>{{ t('offers.createSeller') }}</span>
          </Button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="h-4 mb-3">{{ t('offers.locationOnMap') }}</div>
      <div class="map-wrapper">
        <MapEmbed class="map" :place="place" v-model="mapPosition" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, shallowRef, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import PropertyTypeSelect from '@bd/admin/components/PropertyTypeSelect/PropertyTypeSelect.vue'
import MapEmbed from '@bd/admin/components/MapEmbed/MapEmbed.vue'
import {
  TextInput,
  CityAutocomplete,
  DistrictAutocomplete,
  SellerAutocomplete,
  ConfirmDialog,
  Svg,
} from '@bd/components'
import { default as common } from '@bd/admin/config/common.json'
import { useRouter } from 'vue-router'
import OfferStatisticsForm from './OfferStatisticsForm.vue'
import { CityDto, DistrictDto, adminApi, UserRole } from '@bd/api'
import { useLatest, useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { useFormValues, useIsFormDirty } from 'vee-validate'
import { getEditOfferDto } from '@bd/admin/services/offer.service'
import { OffersEventMessages } from '@bd/admin/config/toast'
import { useToast } from 'primevue/usetoast'
import { draftOfferSchema } from '@bd/components/yup'
import OfferAddCityModal from './OfferAddCityModal.vue'

type Form = Partial<OfferDetailsDto>
const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'OfferLocationForm',
  components: {
    PropertyTypeSelect,
    TextInput,
    SellerAutocomplete,
    CityAutocomplete,
    DistrictAutocomplete,
    MapEmbed,
    OfferStatisticsForm,
    OfferAddCityModal,
    ConfirmDialog,
    Svg,
  },
  props: {
    offerIsDraft: {
      type: Boolean,
      default: false,
    },
    isAddOfferMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const toast = useToast()

    const { value: formValues } = useFormValues<Form>()
    const formDirty = useIsFormDirty()
    const isDraftValid = computed(() => {
      return draftOfferSchema.isValidSync({ ...formValues })
    })

    const cities = shallowRef<CityDto[]>([])
    const districts = shallowRef<DistrictDto[]>([])
    const searchSellerList = shallowRef<OfferDetailsDto['offerSellerDto'][]>([])

    const showCityModal = ref(false)
    const showSellerModal = ref(false)

    const place = computed(() => {
      if (!formValues.cityDto) {
        return common.defaultCity
      }

      const addToAddress = (address: string[], value: string | undefined) => {
        if (value?.length) address.push(value)
      }

      const address: string[] = []

      addToAddress(address, formValues.cityDto.cityName)
      addToAddress(address, formValues.districtDto?.districtName)
      addToAddress(address, formValues.street)
      return address.join(' ')
    })

    const searchSellers = useLatest(
      (query: string) => {
        return adminApi.searchClient({
          query,
          userRole: UserRole.SELLER,
          size: 10,
        })
      },
      (response) => {
        searchSellerList.value = response.data.content
      },
    )

    const searchCity = useLatest(
      async (query: string) => {
        return query ? (await adminApi.cities(query)).data.content : []
      },
      (results) => {
        cities.value = results
      },
    )

    const districtsForCity = async (city?: CityDto | null) => {
      return city ? (await adminApi.districtsForCity(city.cityId)).data : []
    }

    const searchDistricts = useLatest(districtsForCity, (resp) => {
      districts.value = resp
    })

    const canAddSeller = computed(() => {
      return !formValues.id || formValues.draft
    })

    const showSellerDetailsNavigation = computed(
      () => formValues.offerSellerDto?.id,
    )

    const goToAddSellerClick = async () => {
      if (formDirty.value && isDraftValid.value) {
        const offerForm = getEditOfferDto({
          ...(formValues as OfferDetailsDto),
          draft: true,
        })

        if (offerForm.id) {
          try {
            await adminApi.updateOffer(offerForm)
            toast.add(OffersEventMessages.updateSuccess)
          } catch (e) {
            toast.add(OffersEventMessages.updateError)
            return
          }
        } else {
          try {
            const { data } = await adminApi.addOffer(offerForm)
            await router.replace({
              name: 'OfferUpdate',
              params: { id: data.value },
            })
            toast.add(OffersEventMessages.saveSuccess)
          } catch (e) {
            toast.add(OffersEventMessages.saveError)
            return
          }
        }
      }
      router.push({ name: 'ClientSave' })
    }

    const goToSellerDetails = () => {
      if (!formValues.offerSellerDto?.id) {
        return
      }
      router.push({
        name: 'ClientDetails',
        params: { id: formValues.offerSellerDto.id },
      })
    }

    // eslint-disable-next-line no-undef
    const mapPosition = computed<google.maps.LatLngLiteral>({
      get() {
        const { latitude, longitude } = formValues
        if (latitude == null || longitude == null) {
          return {
            lat: common.defaultCoords.latitude,
            lng: common.defaultCoords.longitude,
          }
        }
        return {
          lat: latitude,
          lng: longitude,
        }
      },
      set(pos) {
        formValues.latitude = pos.lat
        formValues.longitude = pos.lng
      },
    })

    const oldSellerDto = ref<OfferDetailsDto['offerSellerDto']>()

    const onWarningModal = (seller: OfferDetailsDto['offerSellerDto']) => {
      oldSellerDto.value = seller
      showSellerModal.value = true
    }

    const onSellerConfirm = () => {
      showSellerModal.value = false
    }

    const onSellerReject = () => {
      formValues.offerSellerDto = oldSellerDto.value
      showSellerModal.value = false
    }

    const { value } = useOfferField('cityDto')

    const setCityValue = (payload: CityDto) => {
      value.value = payload
      showCityModal.value = false
    }
    watch(
      () => formValues.cityDto,
      (city) => searchDistricts(city),
      { immediate: true },
    )

    return {
      t,
      searchCity,
      cities,
      districts,
      place,
      goToAddSellerClick,
      canAddSeller,
      searchSellerList,
      searchSellers,
      mapPosition,
      showCityModal,
      setCityValue,
      showSellerDetailsNavigation,
      goToSellerDetails,
      showSellerModal,
      onSellerConfirm,
      onSellerReject,
      onWarningModal,
    }
  },
})
</script>

<style lang="scss" scoped>
.add-btn {
  padding-top: 20px;
}

.map-wrapper {
  padding: 10px;
  background-color: $white;
  border-radius: 6px;

  @include breakpoint-down(md) {
    padding: 0;
  }
}

.map {
  height: 350px;
  border-radius: 6px;

  @include breakpoint-down(md) {
    height: 140px;
  }
}
.btn-icon {
  @include uniform-size(35px);
}
</style>
