import { AddOfferStatisticsForm } from '@bd/api/admin-api/types'
import { PublicFormContext } from 'vee-validate'
import { provide, inject, shallowRef, InjectionKey, onUnmounted } from 'vue'

type Values = Partial<AddOfferStatisticsForm>

interface StatsChild {
  getInitValues: () => Values
  setForm(formInstance: PublicFormContext<Values> | undefined): void
}

const TOKEN = Symbol('Statictics') as InjectionKey<StatsChild>

export const useStatisticsRoot = (init: Values) => {
  const form = shallowRef<PublicFormContext<Values>>()

  const child: StatsChild = {
    getInitValues: () => ({ ...init }),
    setForm(formInstance: PublicFormContext<Values>) {
      form.value = formInstance
    },
  }

  provide(TOKEN, child)
  return {
    form,
  }
}

export const useStatisticsChild = () => {
  const child = inject(TOKEN)
  if (!child) {
    throw Error(`Can't inject ${TOKEN}`)
  }
  onUnmounted(() => {
    child.setForm(undefined)
  })
  return child
}
