import { TOAST_CONFIG, Toasts } from '@bd/components'
import { i18n } from '@bd/admin/plugins/vue-i18n-next-plugin'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

export const EmployeeEventMessages = Toasts({
  removeSuccess: {
    severity: 'success',
    summary: t('toastMessage.employees.successRemove'),
    life: messageDuration.success,
  },
  removeError: {
    severity: 'error',
    summary: t('toastMessage.employees.errorRemove'),
    life: messageDuration.error,
  },
  blockSuccess: {
    severity: 'success',
    summary: t('toastMessage.employees.successBlock'),
    life: messageDuration.success,
  },
  blockError: {
    severity: 'error',
    summary: t('toastMessage.employees.errorBlock'),
    life: messageDuration.error,
  },
  unblockSuccess: {
    severity: 'success',
    summary: t('toastMessage.employees.successUnblock'),
    life: messageDuration.success,
  },
  unblockError: {
    severity: 'error',
    summary: t('toastMessage.employees.errorUnblock'),
    life: messageDuration.error,
  },
  addSuccess: {
    severity: 'success',
    summary: t('toastMessage.employees.successAdd'),
    life: messageDuration.success,
  },
  addError: {
    severity: 'error',
    summary: t('toastMessage.employees.errorAdd'),
    life: messageDuration.error,
  },
  updateSuccess: {
    severity: 'success',
    summary: t('toastMessage.employees.successUpdate'),
    life: messageDuration.success,
  },
  updateError: {
    severity: 'error',
    summary: t('toastMessage.employees.errorUpdate'),
    life: messageDuration.error,
  },
})

export const ClientEventMessages = Toasts({
  saveSuccess: {
    severity: 'success',
    summary: t('toastMessage.clients.successAdd'),
    life: messageDuration.success,
  },
  saveError: {
    severity: 'error',
    summary: t('toastMessage.clients.errorAdd'),
    life: messageDuration.error,
  },
  updateSuccess: {
    severity: 'success',
    summary: t('toastMessage.clients.successUpdate'),
    life: messageDuration.success,
  },
  updateError: {
    severity: 'error',
    summary: t('toastMessage.clients.errorUpdate'),
    life: messageDuration.error,
  },
  blockSuccess: {
    severity: 'success',
    summary: t('toastMessage.clients.successBlock'),
    life: messageDuration.success,
  },
  blockError: {
    severity: 'error',
    summary: t('toastMessage.clients.errorBlock'),
    life: messageDuration.error,
  },
  unblockSuccess: {
    severity: 'success',
    summary: t('toastMessage.clients.successUnblock'),
    life: messageDuration.success,
  },
  unblockError: {
    severity: 'error',
    summary: t('toastMessage.clients.errorUnblock'),
    life: messageDuration.error,
  },
  removeSuccess: {
    severity: 'success',
    summary: t('toastMessage.clients.successRemove'),
    life: messageDuration.success,
  },
  removeError: {
    severity: 'error',
    summary: t('toastMessage.clients.errorRemove'),
    life: messageDuration.error,
  },
})

export const OffersEventMessages = Toasts({
  exportToAsariSuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.successExportToAsari'),
    life: messageDuration.success,
  },
  exportToAsariError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorExportToAsari'),
    life: messageDuration.error,
  },
  addStatisticsSuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.succesAddStatistics'),
    life: messageDuration.success,
  },
  addStatisticsError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorAddStatistics'),
    life: messageDuration.error,
  },
  removeSuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.succesRemove'),
    life: messageDuration.success,
  },
  removeError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorRemove'),
    life: messageDuration.error,
  },
  publicSuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.succesPublish'),
    life: messageDuration.success,
  },
  publicError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorPublish'),
    life: messageDuration.error,
  },
  updateSuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.succesUpdate'),
    life: messageDuration.success,
  },
  updateError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorUpdate'),
    life: messageDuration.error,
  },
  saveSuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.succesSave'),
    life: messageDuration.success,
  },
  saveError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorSave'),
    life: messageDuration.error,
  },
  unpublishSuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.succesUnpublish'),
    life: messageDuration.success,
  },
  unpublishError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorUnpublish'),
    life: messageDuration.error,
  },
  addCitySuccess: {
    severity: 'success',
    summary: t('toastMessage.offers.succesAddCity'),
    life: messageDuration.success,
  },
  addCityError: {
    severity: 'error',
    summary: t('toastMessage.offers.errorAddCity'),
    life: messageDuration.error,
  },
})
