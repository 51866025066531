
import { computed, defineComponent, shallowRef, watch, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import PropertyTypeSelect from '@bd/admin/components/PropertyTypeSelect/PropertyTypeSelect.vue'
import MapEmbed from '@bd/admin/components/MapEmbed/MapEmbed.vue'
import {
  TextInput,
  CityAutocomplete,
  DistrictAutocomplete,
  SellerAutocomplete,
  ConfirmDialog,
  Svg,
} from '@bd/components'
import { default as common } from '@bd/admin/config/common.json'
import { useRouter } from 'vue-router'
import OfferStatisticsForm from './OfferStatisticsForm.vue'
import { CityDto, DistrictDto, adminApi, UserRole } from '@bd/api'
import { useLatest, useFieldOf } from '@bd/helpers'
import { OfferDetailsDto } from '@bd/store-modules/types'
import { useFormValues, useIsFormDirty } from 'vee-validate'
import { getEditOfferDto } from '@bd/admin/services/offer.service'
import { OffersEventMessages } from '@bd/admin/config/toast'
import { useToast } from 'primevue/usetoast'
import { draftOfferSchema } from '@bd/components/yup'
import OfferAddCityModal from './OfferAddCityModal.vue'

type Form = Partial<OfferDetailsDto>
const useOfferField = useFieldOf<OfferDetailsDto>()

export default defineComponent({
  name: 'OfferLocationForm',
  components: {
    PropertyTypeSelect,
    TextInput,
    SellerAutocomplete,
    CityAutocomplete,
    DistrictAutocomplete,
    MapEmbed,
    OfferStatisticsForm,
    OfferAddCityModal,
    ConfirmDialog,
    Svg,
  },
  props: {
    offerIsDraft: {
      type: Boolean,
      default: false,
    },
    isAddOfferMode: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const toast = useToast()

    const { value: formValues } = useFormValues<Form>()
    const formDirty = useIsFormDirty()
    const isDraftValid = computed(() => {
      return draftOfferSchema.isValidSync({ ...formValues })
    })

    const cities = shallowRef<CityDto[]>([])
    const districts = shallowRef<DistrictDto[]>([])
    const searchSellerList = shallowRef<OfferDetailsDto['offerSellerDto'][]>([])

    const showCityModal = ref(false)
    const showSellerModal = ref(false)

    const place = computed(() => {
      if (!formValues.cityDto) {
        return common.defaultCity
      }

      const addToAddress = (address: string[], value: string | undefined) => {
        if (value?.length) address.push(value)
      }

      const address: string[] = []

      addToAddress(address, formValues.cityDto.cityName)
      addToAddress(address, formValues.districtDto?.districtName)
      addToAddress(address, formValues.street)
      return address.join(' ')
    })

    const searchSellers = useLatest(
      (query: string) => {
        return adminApi.searchClient({
          query,
          userRole: UserRole.SELLER,
          size: 10,
        })
      },
      (response) => {
        searchSellerList.value = response.data.content
      },
    )

    const searchCity = useLatest(
      async (query: string) => {
        return query ? (await adminApi.cities(query)).data.content : []
      },
      (results) => {
        cities.value = results
      },
    )

    const districtsForCity = async (city?: CityDto | null) => {
      return city ? (await adminApi.districtsForCity(city.cityId)).data : []
    }

    const searchDistricts = useLatest(districtsForCity, (resp) => {
      districts.value = resp
    })

    const canAddSeller = computed(() => {
      return !formValues.id || formValues.draft
    })

    const showSellerDetailsNavigation = computed(
      () => formValues.offerSellerDto?.id,
    )

    const goToAddSellerClick = async () => {
      if (formDirty.value && isDraftValid.value) {
        const offerForm = getEditOfferDto({
          ...(formValues as OfferDetailsDto),
          draft: true,
        })

        if (offerForm.id) {
          try {
            await adminApi.updateOffer(offerForm)
            toast.add(OffersEventMessages.updateSuccess)
          } catch (e) {
            toast.add(OffersEventMessages.updateError)
            return
          }
        } else {
          try {
            const { data } = await adminApi.addOffer(offerForm)
            await router.replace({
              name: 'OfferUpdate',
              params: { id: data.value },
            })
            toast.add(OffersEventMessages.saveSuccess)
          } catch (e) {
            toast.add(OffersEventMessages.saveError)
            return
          }
        }
      }
      router.push({ name: 'ClientSave' })
    }

    const goToSellerDetails = () => {
      if (!formValues.offerSellerDto?.id) {
        return
      }
      router.push({
        name: 'ClientDetails',
        params: { id: formValues.offerSellerDto.id },
      })
    }

    // eslint-disable-next-line no-undef
    const mapPosition = computed<google.maps.LatLngLiteral>({
      get() {
        const { latitude, longitude } = formValues
        if (latitude == null || longitude == null) {
          return {
            lat: common.defaultCoords.latitude,
            lng: common.defaultCoords.longitude,
          }
        }
        return {
          lat: latitude,
          lng: longitude,
        }
      },
      set(pos) {
        formValues.latitude = pos.lat
        formValues.longitude = pos.lng
      },
    })

    const oldSellerDto = ref<OfferDetailsDto['offerSellerDto']>()

    const onWarningModal = (seller: OfferDetailsDto['offerSellerDto']) => {
      oldSellerDto.value = seller
      showSellerModal.value = true
    }

    const onSellerConfirm = () => {
      showSellerModal.value = false
    }

    const onSellerReject = () => {
      formValues.offerSellerDto = oldSellerDto.value
      showSellerModal.value = false
    }

    const { value } = useOfferField('cityDto')

    const setCityValue = (payload: CityDto) => {
      value.value = payload
      showCityModal.value = false
    }
    watch(
      () => formValues.cityDto,
      (city) => searchDistricts(city),
      { immediate: true },
    )

    return {
      t,
      searchCity,
      cities,
      districts,
      place,
      goToAddSellerClick,
      canAddSeller,
      searchSellerList,
      searchSellers,
      mapPosition,
      showCityModal,
      setCityValue,
      showSellerDetailsNavigation,
      goToSellerDetails,
      showSellerModal,
      onSellerConfirm,
      onSellerReject,
      onWarningModal,
    }
  },
})
