
import { computed, defineComponent, ref } from 'vue'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import { useI18n } from 'vue-i18n'
import OfferSaveForm from '@bd/admin/components/Offers/OfferSaveForm/OfferSaveForm.vue'
import { useRoute, useRouter } from 'vue-router'
import { useAppStore } from '@bd/admin/store'
import { OffersEventMessages } from '@bd/admin/config/toast'
import { useToast } from 'primevue/usetoast'
import { draftOfferSchema, offerSchema } from '@bd/components/yup'
import { getLocalIsoDate, toRawDeep } from '@bd/helpers'
import { useForm } from 'vee-validate'
import { OfferDetailsDto } from '@bd/store-modules/types'
import {
  AddOfferStatisticsDto,
  AddOfferStatisticsForm,
} from '@bd/api/admin-api/types'
import common from '@bd/admin/config/common.json'
import { useStatisticsRoot } from '@bd/admin/services/statistics.service'
import {
  getEditOfferDto,
  handleOfferError,
} from '@bd/admin/services/offer.service'
import { noop } from '@vueuse/shared'
import { startOfWeek, addDays } from 'date-fns'
import * as Yup from 'yup'
import { PropertyMarket } from '@bd/api'
import { ConfirmDialog } from '@bd/components'

const fillDefaults = (offer: Partial<OfferDetailsDto>) => ({
  draft: false,
  agentHasKeys: false,
  propertyMarket: PropertyMarket.SECONDARY,
  ...common.defaultCoords,
  ...toRawDeep(offer),
})

export default defineComponent({
  name: 'OfferSave',
  components: { AppContentLayout, OfferSaveForm, ConfirmDialog },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const isAddOfferMode = computed(() => route.name === 'OfferSave')

    const initialValues = computed<Partial<OfferDetailsDto>>(() => {
      const offer = isAddOfferMode.value
        ? fillDefaults({})
        : fillDefaults(store.state.offers?.offerDetails || {})
      return offer
    })

    const firstDayOfWeek = +t('date_time.firstDayOfWeek')
    const weekStartDay = startOfWeek(new Date(), {
      weekStartsOn: firstDayOfWeek as never,
    })
    const weekEndDay = addDays(weekStartDay, 6)

    const statistics = useStatisticsRoot({
      offerId: initialValues.value.id,
      startDate: weekStartDay,
      endDate: weekEndDay,
      numberOfInquiries: 0,
      numberOfViews: 0,
    })

    const title = computed(() => {
      return isAddOfferMode.value
        ? t('offers.addTitle')
        : t('offers.updateTitle')
    })

    const schema = Yup.lazy((form: Partial<OfferDetailsDto>) => {
      return form.draft ? draftOfferSchema : offerSchema
    })

    const form = useForm<Partial<OfferDetailsDto>>({
      initialValues: initialValues,
      // Lazy<Yup.AnyObjectSchema, any> is not a proper type for validationSchema, yet it should be
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      validationSchema: schema as any,
    })

    const exportToAsari = async () => {
      try {
        await store.dispatch('offers/exportToAsari', initialValues.value.id)
        toast.add(OffersEventMessages.exportToAsariSuccess)
      } catch (e) {
        toast.add(OffersEventMessages.exportToAsariError)
      }
    }

    const onAddStatisticsClick = computed(() => {
      if (!statistics.form.value) return noop

      return statistics.form.value.handleSubmit(async (stats) => {
        try {
          const payload: AddOfferStatisticsDto = {
            ...(stats as AddOfferStatisticsForm),
            endDate: getLocalIsoDate(stats.endDate!),
            startDate: getLocalIsoDate(stats.startDate!),
          }
          await store.dispatch('offers/addStatistics', payload)

          statistics.form.value!.values.numberOfViews = 0
          statistics.form.value!.values.numberOfInquiries = 0
          toast.add(OffersEventMessages.addStatisticsSuccess)
        } catch (e) {
          toast.add(OffersEventMessages.addStatisticsError)
        }
      })
    })

    const removeOffer = async () => {
      try {
        await store.dispatch('offers/removeOffer', initialValues.value.id)
        await router.push({ name: 'Offers' })
        toast.add(OffersEventMessages.removeSuccess)
      } catch (e) {
        toast.add(OffersEventMessages.removeError)
      }
    }

    const unpublishOffer = async () => {
      try {
        await store.dispatch('offers/unpublishOffer', initialValues.value.id)
        await router.push({ name: 'Offers' })
        toast.add(OffersEventMessages.unpublishSuccess)
      } catch (e) {
        toast.add(OffersEventMessages.unpublishError)
      }
    }

    const saveAsDraft = form.handleSubmit(async (offer, context) => {
      const offerForm = getEditOfferDto(offer as OfferDetailsDto)
      try {
        await store.dispatch('offers/saveAsDraft', offerForm)
        await router.push({ name: 'Offers' })
        toast.add(OffersEventMessages.saveSuccess)
      } catch (e) {
        const errorToast = handleOfferError(e, context)
        toast.add(errorToast || OffersEventMessages.saveError)
      }
    })

    const updateOfferOrDraft = form.handleSubmit(async (offer, context) => {
      const offerForm = getEditOfferDto(offer as OfferDetailsDto)
      try {
        await store.dispatch('offers/updateOffer', offerForm)
        await store.dispatch('offers/getOfferDetails', offerForm.id)
        toast.add(OffersEventMessages.updateSuccess)
      } catch (e) {
        const errorToast = handleOfferError(e, context)
        toast.add(errorToast || OffersEventMessages.updateError)
      }
    })

    const saveAndPublishOffer = form.handleSubmit(async (offer, context) => {
      const offerForm = getEditOfferDto(offer as OfferDetailsDto)
      try {
        await store.dispatch('offers/saveAndPublishOffer', offerForm)
        await router.push({ name: 'Offers' })
        toast.add(OffersEventMessages.publicSuccess)
      } catch (e) {
        const errorToast = handleOfferError(e, context)
        toast.add(errorToast || OffersEventMessages.publicError)
      }
    })

    const updateAndPublishOffer = form.handleSubmit(async (offer, context) => {
      const offerForm = getEditOfferDto(offer as OfferDetailsDto)
      try {
        await store.dispatch('offers/updateAndPublishOffer', offerForm)
        await router.push({ name: 'Offers' })
        toast.add(OffersEventMessages.publicSuccess)
      } catch (e) {
        const errorToast = handleOfferError(e, context)
        toast.add(errorToast || OffersEventMessages.publicError)
      }
    })

    const submit = (payload: { asDraft: boolean }) => {
      form.values.draft = payload.asDraft
      if (isAddOfferMode.value) {
        return payload.asDraft ? saveAsDraft() : saveAndPublishOffer()
      }
      updateOfferOrDraft()
    }

    const showRemoveConfirmDialog = ref(false)

    const confirmOfferRemove = () => {
      removeOffer()
      showRemoveConfirmDialog.value = false
    }
    const rejectOfferRemove = () => {
      showRemoveConfirmDialog.value = false
    }

    return {
      t,
      isAddOfferMode,
      title,
      exportToAsari,
      onAddStatisticsClick,
      removeOffer,
      updateAndPublishOffer,
      unpublishOffer,
      submit,
      form,
      showRemoveConfirmDialog,
      confirmOfferRemove,
      rejectOfferRemove,
    }
  },
})
