
import { computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { NumberInput, DatePicker } from '@bd/components'
import { AddOfferStatisticsForm } from '@bd/api/admin-api/types'
import { useForm } from 'vee-validate'
import { useStatisticsChild } from '@bd/admin/services/statistics.service'
import { statisticsSchema } from '@bd/components/yup'
import { addDays } from 'date-fns'

export default defineComponent({
  name: 'OfferStatisticsForm',
  components: {
    NumberInput,
    DatePicker,
  },
  setup() {
    const { t } = useI18n()
    const stats = useStatisticsChild()

    const form = useForm<Partial<AddOfferStatisticsForm>>({
      initialValues: stats.getInitValues(),
      validationSchema: statisticsSchema,
    })

    const firstDayOfWeek = +t('date_time.firstDayOfWeek')

    /* Disable all week days apart from the first day of the week */
    const disabledDays = computed(() => {
      return Array.from({ length: 7 }, (_, index) => index).filter(
        (val) => val !== firstDayOfWeek,
      )
    })

    const onDateFromChanged = (date: Date) => {
      form.setFieldValue('endDate', addDays(date, 6))
    }

    stats.setForm(form)

    return {
      t,
      form,
      disabledDays,
      onDateFromChanged,
    }
  },
})
